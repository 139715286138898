import { FC } from "react";
import { Box, Typography } from "@mui/material";
import roadmap from "../images/roadmap1.png";
import Graph from "./shema-process-bim/Graph";
import { useStyles } from "./styles";
import ScrollToTop from "react-scroll-to-top";

const Page: FC = () => {
  const classes = useStyles();

  // JSX
  return (
    <Box className={classes.container}>
      <Box className={classes.content}>
        <Box className={classes.head}>
          <div className={classes.title}>
            <img src={roadmap} alt="" className={classes.logRoadmap} />
            <Typography
              variant="h4"
              style={{ color: "#005543", fontFamily: "politica-regular" }}
            >
              Roadmap BIM
            </Typography>
          </div>
          <div className={classes.below_title}>
            Guide étape par étape du processus BIM chez{" "}
            <span style={{ fontWeight: "bold" }}>INTEGRALE Ingénierie</span>
          </div>
        </Box>
        <Box className={classes.shema}>
          <Graph />
        </Box>
      </Box>

      {/* Scroll to top...................... */}
      <ScrollToTop
        style={{ marginBottom: "10px", backgroundColor: "#4d4d4d" }}
        smooth
        color="#fff"
      />
    </Box>
  );
};

export default Page;
