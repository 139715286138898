import { makeStyles } from "@material-ui/core";

// CSS on line
export const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    maxWidth: "950px",
    margin: "0 auto",
  },
  content: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  head: {
    marginBottom: theme.spacing(2),
  },
  logRoadmap: {
    width: "40px",
    marginRight: theme.spacing(1),
  },
  title: {
    display: "flex",
    marginBottom: theme.spacing(1),
  },
  below_title: {
    marginLeft: "12px",
    fontFamily: "montserrat-light",
    fontSize: "18px",
    marginTop: theme.spacing(2),
  },
  shema: {
    marginTop: theme.spacing(5),
  },
}));
