import { useState, FC } from "react";
import { detailsRoadmap } from "../../constants/detailsRoadmap";
import DialogScrollPaper from "../../popop/DialogScrollPaper";
import { useStyles } from "./styles";

const Graph: FC = () => {
  const classes = useStyles();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [caseNum, setCaseNum] = useState<number>();

  // Pour fermer le popop (modal)
  const handleClose = () => setOpenModal(false);

  // Pour ouvrir le popop
  const handleShowModal = (value: number) => {
    setOpenModal(true);
    setCaseNum(value);
  };

  // Pour ouvrir les détails roadmap
  const handleShowDetails = (arg: number | undefined) => {
    return detailsRoadmap.filter((item) => item.id === arg);
  };

  // JSX
  return (
    <div className={classes.container}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="149 200 1182 1600"
      >
        {/* path top vers texte bim */}
        <path
          d="M 840 210 q 10 40 -40 90"
          fill="none"
          stroke="#005543"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDasharray="0.8 12"
        />
        {/* Texte BIM ........... */}
        <g data-group-id="bim">
          <text
            x="744.35"
            y="330"
            fill="rgb(0,0,0)"
            fontStyle="normal"
            fontWeight="normal"
            fontSize="30px"
          >
            <tspan>BIM</tspan>
          </text>
        </g>

        {/* Début path courbe vertical center............................ */}
        {/* path text bim ===> définition bim */}
        <path
          d="M 750 350 q -60 70 0 150"
          fill="none"
          stroke="#005543"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDasharray="undefined"
        />

        {/* path def bim ===> piloter projet bim */}
        <path
          d="M 750 500 q 90 100 0 250"
          fill="none"
          stroke="#005543"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDasharray="undefined"
        />
        {/* path pilote ===> maquette 3d */}
        <path
          d="M 750 750 q -90 100 0 200"
          fill="none"
          stroke="#005543"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDasharray="undefined"
        />
        {/* path maquette 3d ===> synthese 3d */}
        <path
          d="M 750 950 q 100 100 0 500"
          fill="none"
          stroke="#005543"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDasharray="undefined"
        />
        {/* path synthese 3d ===> en bas */}
        <path
          d="M 735 1500 q -30 80 0 150"
          fill="none"
          stroke="#005543"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDasharray="0.8 12"
        />
        {/* Fin path courbe vertical center.........*/}

        {/* Début partie définition du BIM.......................... */}
        <g
          className={classes.g}
          data-group-id="definition_bim"
          onClick={() => handleShowModal(1)}
        >
          <rect
            x="594.35"
            y="450.35"
            width="300"
            height="47.3"
            rx="2"
            fill="#005543"
            fillOpacity="1"
            stroke="#005543"
            strokeWidth="2.7"
          ></rect>
          <text
            x="651"
            y="480.5"
            fill="#fff"
            fontStyle="normal"
            fontWeight="normal"
            fontSize="20px"
          >
            <tspan>Qu'est-ce que le BIM</tspan>
          </text>
        </g>
        <g
          className={classes.g}
          data-group-id="quelques_base_it"
          onClick={() => handleShowModal(2)}
        >
          <rect
            x="150"
            y="418.35"
            width="296.3"
            height="41.3"
            rx="2"
            fill="#fff"
            fillOpacity="1"
            stroke="#005543"
            strokeWidth="2.7"
          ></rect>
          <text
            x="218"
            y="445.5"
            fill="rgb(0,0,0)"
            fontStyle="normal"
            fontWeight="normal"
            fontSize="19px"
          >
            <tspan>Quelques base d'IT</tspan>
          </text>
        </g>
        <g
          className={classes.g}
          data-group-id="quelques_bases_software"
          onClick={() => handleShowModal(3)}
        >
          <rect
            x="150"
            y="480"
            width="296.3"
            height="41.3"
            rx="2"
            fill="#fff"
            fillOpacity="1"
            stroke="#005543"
            strokeWidth="2.7"
          ></rect>
          <text
            x="200"
            y="505.5"
            fill="rgb(0,0,0)"
            fontStyle="normal"
            fontWeight="normal"
            fontSize="19px"
          >
            <tspan>Quelques bases software</tspan>
          </text>
        </g>
        <g
          className={classes.g}
          data-group-id="bim_france"
          onClick={() => handleShowModal(4)}
        >
          <rect
            x="1050"
            y="418.35"
            width="277.3"
            height="37.3"
            rx="2"
            fill="#fff"
            fillOpacity="1"
            stroke="#005543"
            strokeWidth="2.7"
          ></rect>
          <text
            x="1120"
            y="444"
            fill="rgb(0,0,0)"
            fontStyle="normal"
            fontWeight="normal"
            fontSize="19px"
          >
            <tspan>Le BIM en France</tspan>
          </text>
        </g>
        <g
          className={classes.g}
          data-group-id="bim_reste_du_monde"
          onClick={() => handleShowModal(5)}
        >
          <rect
            x="1050"
            y="480"
            width="277.3"
            height="37.3"
            rx="2"
            fill="#fff"
            fillOpacity="1"
            stroke="#005543"
            strokeWidth="2.7"
          ></rect>
          <text
            x="1070"
            y="505.5"
            fill="rgb(0,0,0)"
            fontStyle="normal"
            fontWeight="normal"
            fontSize="19px"
          >
            <tspan>Le BIM dans le reste du monde</tspan>
          </text>
        </g>

        {/* path it ===> bim */}
        <path
          d="M 450 440 l 150 25"
          fill="none"
          stroke="#005543"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDasharray="0.8 12"
        />
        {/* path software ===> bim */}
        <path
          d="M 450 500 l 150 -20"
          fill="none"
          stroke="#005543"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDasharray="0.8 12"
        />
        {/* path bim fr ===> bim */}
        <path
          d="M 1050 440 l -160 25"
          fill="none"
          stroke="#005543"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDasharray="0.8 12"
        />
        {/* path bim reste du monde ===> bim */}
        <path
          d="M 1050 500 l -155 -20"
          fill="none"
          stroke="#005543"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDasharray="0.8 12"
        />
        {/* Fin partie définition du BIM...............*/}

        {/* Début partie piloter un projet BIM............................ */}
        <g
          className={classes.g}
          data-group-id="pilote_bim"
          onClick={() => handleShowModal(6)}
        >
          <rect
            x="594.35"
            y="750.35"
            width="300"
            height="47.3"
            rx="2"
            fill="#005543"
            fillOpacity="1"
            stroke="#005543"
            strokeWidth="2.7"
          ></rect>
          <text
            x="651"
            y="779"
            fill="#fff"
            fontStyle="normal"
            fontWeight="normal"
            fontSize="20px"
          >
            <tspan>Piloter un Projet BIM</tspan>
          </text>
        </g>
        <g
          className={classes.g}
          data-group-id="charte_bim"
          onClick={() => handleShowModal(7)}
        >
          <rect
            x="150"
            y="689.35"
            width="296.3"
            height="41.3"
            rx="2"
            fill="#fff"
            fillOpacity="1"
            stroke="#005543"
            strokeWidth="2.7"
          ></rect>
          <text
            x="218"
            y="715.5"
            fill="rgb(0,0,0)"
            fontStyle="normal"
            fontWeight="normal"
            fontSize="19px"
          >
            <tspan>La Charte BIM</tspan>
          </text>
        </g>
        <g
          className={classes.g}
          data-group-id="convention_bim"
          onClick={() => handleShowModal(8)}
        >
          <rect
            x="150"
            y="750.35"
            width="296.3"
            height="41.3"
            rx="2"
            fill="#fff"
            fillOpacity="1"
            stroke="#005543"
            strokeWidth="2.7"
          ></rect>
          <text
            x="216"
            y="775.5"
            fill="rgb(0,0,0)"
            fontStyle="normal"
            fontWeight="normal"
            fontSize="19px"
          >
            <tspan>La Convention BIM</tspan>
          </text>
        </g>
        <g
          className={classes.g}
          data-group-id="cdc"
          onClick={() => handleShowModal(9)}
        >
          <rect
            x="150"
            y="810.35"
            width="296.3"
            height="41.3"
            rx="2"
            fill="#fff"
            fillOpacity="1"
            stroke="#005543"
            strokeWidth="2.7"
          ></rect>
          <text
            x="213"
            y="835.5"
            fill="rgb(0,0,0)"
            fontStyle="normal"
            fontWeight="normal"
            fontSize="19px"
          >
            <tspan>Le Cahier des charges</tspan>
          </text>
        </g>
        <g
          className={classes.g}
          data-group-id="cde"
          onClick={() => handleShowModal(10)}
        >
          <rect
            x="1050"
            y="670"
            width="277.3"
            height="37.3"
            rx="2"
            fill="#fff"
            fillOpacity="1"
            stroke="#005543"
            strokeWidth="2.7"
          ></rect>
          <text
            x="1150"
            y="695.5"
            fill="rgb(0,0,0)"
            fontStyle="normal"
            fontWeight="normal"
            fontSize="19px"
          >
            <tspan>Les CDE</tspan>
          </text>
        </g>
        <g
          className={classes.g}
          data-group-id="norme_bim"
          onClick={() => handleShowModal(11)}
        >
          <rect
            x="1050"
            y="729"
            width="277.3"
            height="37.3"
            rx="2"
            fill="#fff"
            fillOpacity="1"
            stroke="#005543"
            strokeWidth="2.7"
          ></rect>
          <text
            x="1120"
            y="752.5"
            fill="rgb(0,0,0)"
            fontStyle="normal"
            fontWeight="normal"
            fontSize="19px"
          >
            <tspan>Les Normes du BIM</tspan>
          </text>
        </g>
        <g
          className={classes.g}
          data-group-id="droit_bim"
          onClick={() => handleShowModal(12)}
        >
          <rect
            x="1050"
            y="785"
            width="277.3"
            height="37.3"
            rx="2"
            fill="#fff"
            fillOpacity="1"
            stroke="#005543"
            strokeWidth="2.7"
          ></rect>
          <text
            x="1120"
            y="808.5"
            fill="rgb(0,0,0)"
            fontStyle="normal"
            fontWeight="normal"
            fontSize="19px"
          >
            <tspan>Le Droit du BIM</tspan>
          </text>
        </g>
        <g
          className={classes.g}
          data-group-id="role_bim"
          onClick={() => handleShowModal(13)}
        >
          <rect
            x="1050"
            y="840"
            width="277.3"
            height="37.3"
            rx="2"
            fill="#fff"
            fillOpacity="1"
            stroke="#005543"
            strokeWidth="2.7"
          ></rect>
          <text
            x="1120"
            y="862.5"
            fill="rgb(0,0,0)"
            fontStyle="normal"
            fontWeight="normal"
            fontSize="19px"
          >
            <tspan>Les rôle du BIM</tspan>
          </text>
        </g>
        {/* path charte bim ===> piloter un projet bim */}
        <path
          d="M 450 710 l 145 50"
          fill="none"
          stroke="#005543"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDasharray="0.8 12"
        />
        {/* path convention bim ===> piloter un projet bim */}
        <path
          d="M 450 770 l 145 0"
          fill="none"
          stroke="#005543"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDasharray="0.8 12"
        />
        {/* path cdc ===> piloter un projet bim */}
        <path
          d="M 450 830 l 145 -50"
          fill="none"
          stroke="#005543"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDasharray="0.8 12"
        />
        {/* path cde ===> piloter un projet bim */}
        <path
          d="M 1050 690 l -155 65"
          fill="none"
          stroke="#005543"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDasharray="0.8 12"
        />
        {/* path norme bim ===> piloter un projet bim */}
        <path
          d="M 1050 740 l -155 30"
          fill="none"
          stroke="#005543"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDasharray="0.8 12"
        />
        {/* path droit bim ===> piloter un projet bim */}
        <path
          d="M 1050 800 l -155 -30"
          fill="none"
          stroke="#005543"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDasharray="0.8 12"
        />
        {/* path role bim ===> piloter un projet bim */}
        <path
          d="M 1050 850 l -155 -70"
          fill="none"
          stroke="#005543"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDasharray="0.8 12"
        />
        {/*Fin partie piloter un projet BIM............ */}

        {/* Début partie Maquette 3D................................... */}
        <g
          className={classes.g}
          data-group-id="maquette_3d"
          onClick={() => handleShowModal(14)}
        >
          <rect
            x="594.35"
            y="925.35"
            width="300"
            height="47.3"
            rx="2"
            fill="#005543"
            fillOpacity="1"
            stroke="#005543"
            strokeWidth="2.7"
          ></rect>
          <text
            x="651"
            y="955"
            fill="#fff"
            fontStyle="normal"
            fontWeight="normal"
            fontSize="20px"
          >
            <tspan>Les Maquettes 3D</tspan>
          </text>
        </g>
        <g
          className={classes.g}
          data-group-id="modelisation"
          onClick={() => handleShowModal(15)}
        >
          <rect
            x="360"
            y="1050.35"
            width="230.3"
            height="41.3"
            rx="2"
            fill="#fff"
            fillOpacity="1"
            stroke="#005543"
            strokeWidth="2.7"
          ></rect>
          <text
            x="400"
            y="1075.5"
            fill="rgb(0,0,0)"
            fontStyle="normal"
            fontWeight="normal"
            fontSize="19px"
          >
            <tspan>La modélisation</tspan>
          </text>
        </g>
        <g
          className={classes.g}
          data-group-id="revit"
          onClick={() => handleShowModal(16)}
        >
          <rect
            x="385"
            y="1145.35"
            width="180"
            height="41.3"
            rx="2"
            fill="#fff"
            fillOpacity="1"
            stroke="#005543"
            strokeWidth="2.7"
          ></rect>
          <text
            x="445"
            y="1170.5"
            fill="rgb(0,0,0)"
            fontStyle="normal"
            fontWeight="normal"
            fontSize="19px"
          >
            <tspan>Revit</tspan>
          </text>
        </g>
        <g
          className={classes.g}
          data-group-id="archicad"
          onClick={() => handleShowModal(17)}
        >
          <rect
            x="385"
            y="1195.35"
            width="180"
            height="41.3"
            rx="2"
            fill="#fff"
            fillOpacity="1"
            stroke="#005543"
            strokeWidth="2.7"
          ></rect>
          <text
            x="435"
            y="1222.5"
            fill="rgb(0,0,0)"
            fontStyle="normal"
            fontWeight="normal"
            fontSize="19px"
          >
            <tspan>ArchiCAD</tspan>
          </text>
        </g>
        <g
          className={classes.g}
          data-group-id="tekla"
          onClick={() => handleShowModal(18)}
        >
          <rect
            x="385"
            y="1245.35"
            width="180"
            height="41.3"
            rx="2"
            fill="#fff"
            fillOpacity="1"
            stroke="#005543"
            strokeWidth="2.7"
          ></rect>
          <text
            x="445"
            y="1270.5"
            fill="rgb(0,0,0)"
            fontStyle="normal"
            fontWeight="normal"
            fontSize="19px"
          >
            <tspan>TEKLA</tspan>
          </text>
        </g>
        <g
          className={classes.g}
          data-group-id="all_plan"
          onClick={() => handleShowModal(19)}
        >
          <rect
            x="385"
            y="1295.35"
            width="180"
            height="41.3"
            rx="2"
            fill="#fff"
            fillOpacity="1"
            stroke="#005543"
            strokeWidth="2.7"
          ></rect>
          <text
            x="440"
            y="1322.5"
            fill="rgb(0,0,0)"
            fontStyle="normal"
            fontWeight="normal"
            fontSize="19px"
          >
            <tspan>All Plan</tspan>
          </text>
        </g>
        <g
          className={classes.g}
          data-group-id="ifc"
          onClick={() => handleShowModal(20)}
        >
          <rect
            x="890"
            y="1050.35"
            width="130.3"
            height="41.3"
            rx="2"
            fill="#fff"
            fillOpacity="1"
            stroke="#005543"
            strokeWidth="2.7"
          ></rect>
          <text
            x="930"
            y="1075.5"
            fill="rgb(0,0,0)"
            fontStyle="normal"
            fontWeight="normal"
            fontSize="19px"
          >
            <tspan>L'IFC</tspan>
          </text>
        </g>
        <g
          className={classes.g}
          data-group-id="autre_interop"
          onClick={() => handleShowModal(21)}
        >
          <rect
            x="1050"
            y="1050.35"
            width="277.3"
            height="41.3"
            rx="2"
            fill="#fff"
            fillOpacity="1"
            stroke="#005543"
            strokeWidth="2.7"
          ></rect>
          <text
            x="1085"
            y="1075.5"
            fill="rgb(0,0,0)"
            fontStyle="normal"
            fontWeight="normal"
            fontSize="19px"
          >
            <tspan>Les autres.... interopérables</tspan>
          </text>
        </g>
        <g
          className={classes.g}
          data-group-id="interop"
          onClick={() => handleShowModal(22)}
        >
          <rect
            x="890"
            y="1145.35"
            width="200.3"
            height="41.3"
            rx="2"
            fill="#fff"
            fillOpacity="1"
            stroke="#005543"
            strokeWidth="2.7"
          ></rect>
          <text
            x="920"
            y="1170.5"
            fill="rgb(0,0,0)"
            fontStyle="normal"
            fontWeight="normal"
            fontSize="19px"
          >
            <tspan>L'interopérabilité</tspan>
          </text>
        </g>
        <g
          className={classes.g}
          data-group-id="iso"
          onClick={() => handleShowModal(23)}
        >
          <rect
            x="890"
            y="1195.35"
            width="200.3"
            height="41.3"
            rx="2"
            fill="#fff"
            fillOpacity="1"
            stroke="#005543"
            strokeWidth="2.7"
          ></rect>
          <text
            x="940"
            y="1222.5"
            fill="rgb(0,0,0)"
            fontStyle="normal"
            fontWeight="normal"
            fontSize="19px"
          >
            <tspan>L'ISO 19739</tspan>
          </text>
        </g>
        <g
          className={classes.g}
          data-group-id="visio_ifc"
          onClick={() => handleShowModal(24)}
        >
          <rect
            x="890"
            y="1245.35"
            width="200.3"
            height="41.3"
            rx="2"
            fill="#fff"
            fillOpacity="1"
            stroke="#005543"
            strokeWidth="2.7"
          ></rect>
          <text
            x="903"
            y="1270.5"
            fill="rgb(0,0,0)"
            fontStyle="normal"
            fontWeight="normal"
            fontSize="19px"
          >
            <tspan>Les Visionneuses IFC</tspan>
          </text>
        </g>

        {/* path maquette 3d ===> modelisation */}
        <path
          d="M 594 970 l -100 80"
          fill="none"
          stroke="#005543"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDasharray="0.8 12"
        />
        {/* path maquette 3d ===> ifc */}
        <path
          d="M 900 973 l 60 80"
          fill="none"
          stroke="#005543"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDasharray="0.8 12"
        />
        {/* path modelisation ===> revit */}
        <path
          d="M 470 1095 v 50"
          fill="none"
          stroke="#005543"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDasharray="0.8 12"
        />
        {/* path ifc ===> les autres... */}
        <path
          d="M 1022 1070 h 30"
          fill="none"
          stroke="#005543"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDasharray="0.8 12"
        />
        {/* path ifc ===> interop */}
        <path
          d="M 960 1095 v 50"
          fill="none"
          stroke="#005543"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDasharray="0.8 12"
        />
        {/* Fin partie Maquette 3D......... */}

        {/* Début partie synthese.................................... */}
        <g
          className={classes.g}
          data-group-id="synthese_3d"
          onClick={() => handleShowModal(25)}
        >
          <rect
            x="594.35"
            y="1450.35"
            width="300"
            height="47.3"
            rx="2"
            fill="#005543"
            fillOpacity="1"
            stroke="#005543"
            strokeWidth="2.7"
          ></rect>
          <text
            x="675"
            y="1479"
            fill="#fff"
            fontStyle="normal"
            fontWeight="normal"
            fontSize="20px"
          >
            <tspan>La Synthèse 3D</tspan>
          </text>
        </g>
        {/* Fin partie synthese...................*/}
      </svg>

      {/* Ici le composant popop....... */}
      {handleShowDetails(caseNum).map((row) => (
        <DialogScrollPaper
          key={row?.id}
          title={row?.title}
          open={openModal}
          handleClose={handleClose}
        >
          {row?.content}
        </DialogScrollPaper>
      ))}
    </div>
  );
};

export default Graph;
