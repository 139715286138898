import { makeStyles } from "@material-ui/core";

// CSS on line for component Graph
export const useStyles = makeStyles(() => ({
  container: {
    fontFamily: "montserrat-light",
  },
  g: {
    cursor: "pointer",
  },
}));
