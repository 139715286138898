import { FC } from "react";
import { makeStyles } from "@material-ui/core";
import Page from "./components/Page";

// CSS on line
const useStyles = makeStyles((theme) => ({
  app: {
    padding: 0,
    margin: 0,
    display: "flex",
    [theme.breakpoints.up("sm")]: {
      backgroundImage: `url(https://cdn.discordapp.com/attachments/1001721724104101949/1100670559085404270/road_map_bim.jpg)`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "hover",
      backgroundAttachment: "fixed",
    },
  },
}));

const App: FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.app}>
      <Page />
    </div>
  );
};

export default App;
