import { makeStyles } from "@material-ui/core/styles";

// CSS on line for component DialogScrollPaper
export const useStyles = makeStyles(() => ({
  title: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: "#fff",
  },
  icon: {
    color: "#fff",
  },
}));
