import { FC } from "react";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useStyles } from "./styles";

interface dataProps {
  title: string;
  open: boolean;
  handleClose: () => void;
  children: any;
}

const DialogScrollPaper: FC<dataProps> = ({
  title,
  open,
  handleClose,
  children,
}) => {
  const classes = useStyles();

  return (
    <div>
      <Dialog open={open} onClose={handleClose} scroll="paper">
        <DialogTitle style={{ backgroundColor: "#9a9a9a" }}>
          <div className={classes.title}>
            <div>{title}</div>
            <Tooltip title={undefined} onClick={handleClose}>
              <IconButton>
                <CloseIcon className={classes.icon} />
              </IconButton>
            </Tooltip>
          </div>
        </DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText>{children}</DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default DialogScrollPaper;
